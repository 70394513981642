<template>
  <section
    v-loading="contactLoading"
    :element-loading-text="contactLoadingtext"
  >
    <el-row :gutter="30">
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <label> First name <span style="color: red; font-weight: bold">*</span> </label>
        <el-input
          v-model="contactFormData.first_name"
          placeholder="First name "
        ></el-input>
        <p
          class="error"
          v-if="
            getContactErrors &&
            getContactErrors.first_name &&
            getContactErrors.first_name != 'First Name is Required!'
          "
        >
          {{ getContactErrors.first_name }}
        </p>
      </el-col>
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <label> Last name  <span style="color: red; font-weight: bold">*</span>  </label>
        <el-input
          v-model="contactFormData.last_name"
          placeholder="Last name"
        ></el-input>
        <p
          class="error"
          v-if="
            getContactErrors &&
            getContactErrors.last_name &&
            getContactErrors.last_name != 'Last Name is Required!'
          "
        >
          {{ getContactErrors.last_name }}
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="30" class="mt-1">
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <label>Email  <span style="color: red; font-weight: bold">*</span> </label>
        <el-input
          v-model="contactFormData.email"
          placeholder="Email"
          readonly
        ></el-input>
        <p
          class="error"
          v-if="
            getContactErrors &&
            getContactErrors.email &&
            getContactErrors.email != 'Email is Required!'
          "
        >
          {{ getContactErrors.email }}
        </p>
        <p
          class="error"
          v-if="getContactErrors && getContactErrors.critical_error"
        >
          {{ getContactErrors.critical_error }}
        </p>
      </el-col>
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <label>Middle name</label>
        <el-input
          v-model="contactFormData.middle_name"
          placeholder="Middle name"
        ></el-input>
        <p
          class="error"
          v-if="getContactErrors && getContactErrors.middle_name"
        >
          {{ getContactErrors.middle_name }}
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="30" class="mt-1">
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <label>Phone</label>
        <el-input
          v-model="contactFormData.phone_number"
          placeholder="Phone number"
        ></el-input>
        <p
          style="font-size: 12px"
          class="error"
          v-if="
            contactFormData &&
            contactFormData.phone_number &&
            checkphone(contactFormData.phone_number)
          "
        >
          Invalid phone number
        </p>
        <p
          class="error"
          v-if="getContactErrors && getContactErrors.phone_number"
        >
          {{ getContactErrors.phone_number }}
        </p>
      </el-col>
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <label>Title</label>
        <el-input
          v-model="contactFormData.title"
          placeholder="Title"
        ></el-input>
        <p class="error" v-if="getContactErrors && getContactErrors.title">
          {{ getContactErrors.title }}
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="30" class="mt-1">
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <label>Contact type <span style="color: red; font-weight: bold">*</span></label>
        <br />
        <el-select
          v-model="contactFormData.contact_type"
          placeholder="Contact type"
          @change="getEntityInfo"
          style="width: 100%"
          :disabled="checkIsDisabled"
          filterable
        >
          <el-option
            v-for="(type, index) in getAllContactTypes"
            :key="index + '_table_' + type._id"
            :value="type._id"
            :label="type.name"
            >{{ type.name }}
            <span style="float: right"
              ><i
                class="el-icon-user"
                v-if="type.entity_type == 'INDIVIDUAL'"
              ></i>
              <i class="el-icon-star-on" v-else> </i
            ></span>
          </el-option>
        </el-select>
        <p
          class="error"
          v-if="
            getContactErrors &&
            getContactErrors.contact_type &&
            getContactErrors.contact_type != 'Contact Type is Required!'
          "
        >
          {{ getContactErrors.contact_type }}
        </p>
      </el-col>
      <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
        <div>
          <label style="float: left !important">Company name</label
          ><el-tooltip
            class="item"
            effect="dark"
            content="Add new data"
            placement="bottom"
          >
            <a
              style="border: 0; cursor: pointer; float: right !important"
              @click="addPrimaryDataDialog = true"
              v-if="isBusinessEntity"
            >
              <i
                class="el-icon-circle-plus-outline"
                style="font-weight: 800; color: #f754a2; font-family: inter"
              ></i>
            </a>
          </el-tooltip>
        </div>
        <el-select
          v-if="isBusinessEntity"
          autocomplete="on"
          class="w-100"
          placeholder="Search with Account"
          suffix-icon="el-icon-search"
          v-model="contactFormData.account_data_id"
          clearable
          filterable
          default-first-option
          ref="searchSelect"
        >
          <span v-if="accountNames">
            <el-option
              v-for="(data, index) in accountNames"
              :key="index"
              :value="data._id"
              :label="getLabel(data, index)"
              >{{ getLabel(data, index) }}</el-option
            >
          </span>
        </el-select>
        <el-input
          v-model="contactFormData.company_name"
          placeholder="Company name"
          v-else
        ></el-input>
        <p
          class="error"
          v-if="getContactErrors && getContactErrors.company_name"
        >
          {{ getContactErrors.company_name }}
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="30" class="mt-1">
      <el-col :span="24">
        <label>Address</label>
        <el-input
          type="textarea"
          v-model="contactFormData.address"
          placeholder="Address"
        ></el-input>
        <p class="error" v-if="getContactErrors && getContactErrors.address">
          {{ getContactErrors.address }}
        </p>
      </el-col>
    </el-row>
    <div class="mt-4 mb-3 text-right action-button-wrapper">
      <el-row>
        <el-col
          :lg="{ span: 24 }"
          :md="{ span: 24 }"
          :xs="{ span: 24 }"
          :sm="{ span: 24 }"
          :xl="{ span: 24 }"
        >
          <el-button
            type="primary"
            class="save-contact-button action-button"
            :disabled="
              (contactFormData &&
                contactFormData.phone_number &&
                checkphone(contactFormData.phone_number)) ||
              !contactFormData.first_name ||
              !contactFormData.last_name ||
              !contactFormData.email ||
              (!contactFormData.account_data_id &&
                currentEntity.entity_type === 'BUSINESS') ||
              !contactFormData.contact_type
            "
            @click="saveContact"
          >
            Save
          </el-button>
          <el-button class="action-button" @click="close"> Cancel </el-button>
        </el-col>
      </el-row>
    </div>
    <dialog-component
      :title="'Add Account'"
      :visible="addPrimaryDataDialog"
      :containerWidth="getIsMobile ? '100%' : '50%'"
      @before-close="addPrimaryDataDialog=false"
    >
      <el-form ref="form" :model="form" class="sidemenu">
        <PrimaryDataForm
          :fieldsListData="primaryFields"
          :form="form"
          v-if="addPrimaryDataDialog"
          style="padding:10px"
        ></PrimaryDataForm>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="addPrimaryDataToEntity('form')"
          size="small"
          :loading="contactLoading"
        >
          <i class="el-icon-plus"></i> Add Account
        </el-button>
        <el-button
          @click="addPrimaryDataDialog = false"
          :loading="contactLoading"
          size="small"
          >Cancel</el-button
        >
      </span>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["contactData", "selectedContact", "contactTypes", "checkIsDisabled"],
  components: {
    PrimaryDataForm: () => import("../../entity/PrimaryDataCollectionForm"),
  },
  data() {
    return {
      contactLoading: false,
      contactLoadingtext: "",
      contactFormData: {
        first_name: "",
        last_name: "",
        middle_name: "",
        email: "",
        phone_number: "",
        address: "",
        company_name: "",
        contact_type: "",
        title: "",
        account_data_id: "",
        contact_types: [],
      },
      primaryFields: [],
      currentEntity: {},
      accountNames: [],
      entity_id: "",
      addPrimaryDataDialog: false,
      form: {},
    };
  },
  computed: {
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    ...mapGetters("company", ["getListOfCompanies"]),
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
    ]),
    ...mapGetters("contacts", [
      "getCreateContactStatus",
      "getCreateContactData",
      "getContactErrors",
      "getUpdateContactStatus",
      "getContact",
    ]),
    checkphone() {
      let re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      //if(user.phone.length){
      return (phone) => !re.test(phone);
      //}
    },
    isBusinessEntity() {
      if (this.contactFormData?.contact_type) {
        let id = (this.getAllContactTypes || []).find(
          (x) => x._id === this.contactFormData.contact_type
        );
        if (id?.entity_type == "BUSINESS") {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    this.resetForm();
    if (this.contactData) {
      this.contactFormData.email = this.contactData.email;
      this.contactFormData.first_name = this.contactData.first_name;
      this.contactFormData.last_name = this.contactData.last_name;
      this.contactFormData.middle_name = this.contactData.middle_name;
      this.contactFormData.title = this.contactData.title;
      this.contactFormData.address = this.contactData.address;
      this.contactFormData.phone_number = this.contactData.phone;
      this.contactFormData.company_name = this.contactData.company_name;
      this.contactFormData.contact_type = this.contactData.contact_type;
      this.contactFormData.account_data_id = this.contactData.account_data_id;
    }
    await this.$store.commit("contacts/setContactErrors", null, {
      root: true,
    });
    // await this.fetchAllContactTypesData();
    await this.getEntityInfo();
  },
  methods: {
    close() {
      this.$emit("close", {
        createContact: false,
      });
    },
    async backToContacts() {
      console.log(this.$route);
      if (
        this.$route &&
        this.$route.name &&
        (this.$route.name == "add-contact" ||
          this.$route.name == "edit-contact")
      ) {
        this.$router.go(-1);
      } else {
        this.contactLoading = true;
        this.contactLoadingtext = "Loading...";
        await this.$emit("close", {
          createContact: false,
          data: {},
          is_update: false,
          is_creating_new_contact: false,
        });
        this.contactLoading = false;
      }
    },
    async saveContact() {
      let params = this.prepareParams();
      this.contactLoading = true;
      if (this.selectedContact) {
        this.contactLoadingtext = "Updating contact...";
        if (params.account_data_id !== undefined) {
          params.account_data_id = params.account_data_id._id ? params.account_data_id._id : params.account_data_id;
        }
        await this.$store.dispatch("contacts/updateContact", {
          contact_id: this.selectedContact,
          contact: params,
        });
        console.log("this.contactFormData",this.contactFormData)
        if (this.getUpdateContactStatus) {
          this.$notify.success({
            title: "Success",
            message: "Contact updated successfully",
          });
          this.contactFormData._id = this.selectedContact;
          this.contactLoading = false;
          this.$emit("close", {
            createContact: true,
            data: this.contactFormData,
            is_update: true,
            is_creating_new_contact: false,
          });
        } else if (this.getContactErrors) {
          this.contactLoading = false;
          console.log("his.getContactErrors", this.getContactErrors);
          this.$notify.error({
            title: "Error",
            message: "Error in updating Contact",
          });
        } else{
          this.contactLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Error in creating Contact",
          });
        }
      } else {
        this.contactLoadingtext = "Creating contact...";
        await this.$store.dispatch("contacts/createContact", params);
        if (this.getCreateContactStatus) {
          this.contactLoading = false;
          this.$notify.success({
            title: "Success",
            message: "Contact created successfully",
          });
          this.$emit("close", {
            createContact: true,
            data: this.getCreateContactData,
            is_update: false,
            is_creating_new_contact: true,
          });
        } else if (this.getContactErrors && this.getContactErrors.length) {
          this.contactLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Error in creating Contact",
          });
        }else{
          this.contactLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Error in creating Contact",
          });
        }
      }
    },
    prepareParams() {
      let contact_types = [];
      if (this.contactTypes.length) {
        contact_types = this.contactTypes;
      }
      console.log("this.contactFormData",this.contactFormData)
      contact_types.push({
        contact_type: this.contactFormData.contact_type,
        account_data_id: this.contactFormData.account_data_id,
        has_login: true,
      });
      contact_types = contact_types.map((e) => {
        if (e.contact_type) {
          if (e.contact_type._id) {
            e.contact_type = e.contact_type._id;
          }
          let selected = (this.getAllContactTypes || []).find(
            (e) => e._id == e.contact_type
          );
          if (selected && selected.type == "STANDARD") {
            delete e.account_data_id;
          }
          if (e?.account_data_id?._id) {
            e.account_data_id = e.account_data_id._id;
          }
        }
        return e;
      });
      if (this.contactFormData.account_data_id == "") {
        delete this.contactFormData.account_data_id;
      }
      if (!this.contactFormData.address) {
        this.contactFormData.address = "";
      }
      return {
        ...this.contactFormData,
        ...{
          contact_types: contact_types,
        },
      };
    },
    getRequired() {
      return this.getEntityDataById.primaryFields.filter(
        (e) => this.form[e.key]
      ).length;
    },
    getTotalCount() {
      return this.getEntityDataById.primaryFields.filter((e) => e.key).length;
    },
    async addPrimaryDataToEntity() {
      this.contactLoading = true;
      this.contactLoadingtext =
        "Creating new " +
        (this.getEntityDataById?.name ? this.getEntityDataById?.name : " data");
      try {
        if (this.form && this.getRequired() === this.getTotalCount()) {
          let data = {
            primaryFields: this.getEntityDataById.primaryFields,
            templates: this.getEntityDataById.templates,
            formData: this.form,
            entity_id: this.entity_id,
          };
          await this.$store.dispatch(
            "templatesData/createEntityPrimaryData",
            data
          );

          if (this.getPrimaryEntityDataStatus) {
            this.addPrimaryDataDialog = false;
            this.$notify.success({
              title: "Success",
              message: "Account added successfully",
            });
            if (this.getNewEntityData) {
              this.contactLoadingtext =
                "Fetching " +
                (this.getEntityDataById?.name
                  ? this.getEntityDataById?.name
                  : " data");
              await this.$store.dispatch("company/fetchListOfCompanies", {
                contactTypeName: this.getEntityDataById?.name,
              });
              if (this.getListOfCompanies) {
                this.accountNames = this.getListOfCompanies;
                setTimeout(() => {
                  this.contactFormData.account_data_id =
                    this.getNewEntityData._id;
                  this.contactLoading = false;
                }, 1000);
              } else {
                this.contactLoading = false;
              }
              // bus.$emit("next-step", {
              //   newEntityData: this.getNewEntityData,
              //   firstStep: true,
              // });
            } else {
              this.contactLoading = false;
            }
          } else {
            this.contactLoading = false;
            this.$notify.error({
              title: "Error",
              message: "Error at Creating Account",
            });
          }
        } else {
          this.contactLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
      } catch (err) {
        this.contactLoading = false;
        console.log(err);
      }
    },
    getLabel(data) {
      // this.currentEntity.entity_type === "BUSINESS"?
      if (this.currentEntity.entity_type === "BUSINESS" && data) {
        return data?.entity_id &&
          data?.entity_id?.primaryFields &&
          data?.entity_prime_data &&
          data.entity_id.primaryFields.length &&
          data.entity_id.primaryFields[0].key &&
          data.entity_prime_data[data.entity_id.primaryFields[0].key]
          ? data.entity_prime_data[
              data.entity_id.primaryFields[0].key + "/name"
            ]
            ? data.entity_prime_data[
                data.entity_id.primaryFields[0].key + "/name"
              ]
            : data.entity_prime_data[data.entity_id.primaryFields[0].key]
          : Object.keys(data.entity_prime_data) &&
            Object.keys(data.entity_prime_data)[0] &&
            data.entity_prime_data[Object.keys(data.entity_prime_data)[0]]
          ? data.entity_prime_data[Object.keys(data.entity_prime_data)[0]]
          : "";
      } else {
        return data.name;
      }
    },
    async fetchAllContactTypesData() {
      try {
        this.contactLoading = true;
        this.contactLoadingtext = "Getting data";
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          from_contacts: true,
          get_all: true,
          type:'CONTACT_TYPE'
        });
        if (this.getAllContactTypes) {
          this.contactLoading = false;
          if (
            this.getAllContactTypes.length == 1 &&
            !this.contactFormData.contact_type
          ) {
            this.contactFormData.contact_type = this.getAllContactTypes[0]._id;
          }
        } else {
          this.contactLoading = false;
        }
      } catch (err) {
        this.contactLoading = false;
        console.log(err);
      }
    },
    async getEntityInfo(e) {
      if (e) {
        this.contactFormData.company_name = "";
      }
      let id = (this.getAllContactTypes || []).find(
        (x) => x._id === this.contactFormData.contact_type
      );
      if (id?.name == "Receiver") {
        this.accountNames = [];
      }
      if (id) {
        this.currentEntity = id;
        this.entity_id = id._id;
        this.contactLoading = true;
        if (id.entity_type == "BUSINESS") {
          this.contactLoadingtext = "Fetching " + id.name + " data";
          await this.$store.dispatch("company/fetchListOfCompanies", {
            contactTypeName: id.name,
          });
          if (this.getListOfCompanies) {
            this.accountNames = this.getListOfCompanies;
          }
          await this.$store.dispatch("entities/fetchEntityById", {
            entity_id: this.entity_id,
          });
          if (
            this.currentEntity &&
            this.currentEntity.templates &&
            this.currentEntity.entity_type == "BUSINESS" &&
            this.currentEntity.type != "STANDARD" &&
            this.currentEntity.slug != "receiver" &&
            !this.currentEntity.templates.length
          ) {
            this.contactFormData.contact_type = "";
            this.$message({
              message: "No templates included in this entity",
              type: "warning",
            });
          } else if (
            this.currentEntity &&
            this.currentEntity.templates &&
            this.currentEntity.entity_type == "BUSINESS" &&
            this.currentEntity.type != "STANDARD" &&
            this.currentEntity.slug != "receiver" &&
            !this.currentEntity.primaryFields.length &&
            !this.accountNames.length
          ) {
            this.contactFormData.contact_type = "";
            this.$message({
              message: "Primary fields not selected for this entity",
              type: "warning",
            });
          } else {
            if (e) {
              this.contactFormData.company_name = "";
              this.contactFormData.account_data_id = "";
            }
            this.contactFormData.entity_id = this.entity_id;
          }
          this.contactLoading = false;
          this.contactLoadingtext = "";
        } else {
          this.contactLoading = false;
          this.contactLoadingtext = "";
        }
      }
      if (this.currentEntity) {
        this.primaryFields = this.currentEntity.primaryFields;
      }
    },
    resetForm() {
      this.contactFormData.first_name = "";
      this.contactFormData.last_name = "";
      this.contactFormData.email = "";
      this.contactFormData.title = "";
      this.contactFormData.phone_number = "";
      this.contactFormData.address = "";
      this.contactFormData.company_name = "";
      this.contactFormData.contact_type = "";
      this.contactFormData.account_data_id = "";
      this.contactFormData.contact_types = [];
    },
  },
};
</script>
